import { ListItemInterface } from '@components/ListGroup/ListItem';

import { PERMISSION } from './permissions';

export const menus = [
  {
    label: 'Dashboard',
    icon: 'favorite-chart',
    to: '/dashboard',
    permission: PERMISSION.DASHBOARD,
  },
  {
    label: 'Shipment',
    icon: 'products',
    items: [
      {
        label: 'Manage Items Arrived',
        to: '/shipments/shipout-manage-itemsarrived',
        permission: PERMISSION.SHIPMENT_ITEM_ARRIVED,
      },
      {
        label: 'Batch Package List',
        to: '/shipments/batch-package-list',
        permission: PERMISSION.SHIPMENT_BATCH_PACKAGE,
      },
      {
        label: 'Orders - Pending Shipment',
        to: '/shipments/orders-pending',
        permission: PERMISSION.SHIPMENT_ORDER_PENDDING,
      },
      {
        label: 'Orders - Wait for carrier',
        to: '/shipments/orders-wait-carrier-ship',
        permission: PERMISSION.SHIPMENT_ORDER_WAIT_CARRIER,
      },
      {
        label: 'Orders - Shipped',
        to: '/shipments/orders-shipped',
        permission: PERMISSION.SHIPMENT_ORDER_SHIPPED,
      },
      {
        label: 'Batch Package Shipment',
        to: '/shipments/batch-package',
        permission: PERMISSION.SHIPMENT_BATCH_PACKAGE,
      },
    ],
  },
  {
    label: 'Returns',
    icon: 'products',
    items: [
      {
        label: 'Return List',
        to: '/returns/request-items',
        permission: PERMISSION.RETURN_MANAGER,
      },
      {
        label: 'Refund List',
        to: '/returns/refund-items',
        permission: PERMISSION.RETURN_MANAGE_REFUND,
      },
      {
        label: 'Process Item',
        to: '/returns/process-item',
        permission: PERMISSION.RETURN_MANAGE_PROCESS_ITEM,
      },
      {
        label: 'Batch Package Shipment',
        to: '/returns/batch-package-shipment',
        permission: PERMISSION.RETURN_BATCH_PACKAGE,
      },
      {
        label: 'Download Return Code PDF',
        to: '/returns/download-return-code-pdf',
        permission: PERMISSION.RETURN_MANAGER,
      },
    ],
  },
  {
    label: 'Aftership',
    icon: 'favorite-chart',
    permission: PERMISSION.AFTERSHIP,
    items: [
      {
        label: 'Dashboard',
        to: '/aftership/',
      },
      {
        label: 'Manage Shipments',
        to: '/aftership/manage-shipments',
      },
      {
        label: 'Report Shipments',
        to: '/aftership/reports/shipments',
      },
      {
        label: 'Report Exceptions',
        to: '/aftership/reports/exceptions',
      },
      {
        label: 'Report Courier and Destination',
        to: '/aftership/reports/courier-and-destination',
      },
    ],
  },
  {
    label: 'Report',
    icon: 'favorite-chart',
    items: [
      {
        label: 'Overdue Delivery Date',
        to: '/report/shipment-overdue-delivery',
        permission: PERMISSION.REPORT_OVERDUE_DELIVERY_DATE,
      },
      {
        label: 'Late Report',
        to: '/report/late-item',
        permission: PERMISSION.REPORT_LATE,
      },
    ],
  },
  {
    label: 'Team',
    icon: 'profile-2user',
    to: '/members',
    permission: PERMISSION.TEAM,
  },
  {
    label: 'Freshdesk Ticket',
    icon: 'crm',
    permission: PERMISSION.FRESHDESK,
    to: '/ticket/support-ticket',
  },
];

export const categoryNav: ListItemInterface[] = [
  // {
  //   label: 'Laptop',
  //   to: '/',
  //   badge: 8,
  //   badgeClass: 'bg-yellow',
  // },
  // {
  //   label: 'Mobile',
  //   to: '/',
  //   badge: 8,
  //   badgeClass: 'bg-yellow',
  // },
  // {
  //   label: 'Desktop',
  //   to: '/',
  //   badge: 8,
  //   badgeClass: 'bg-yellow',
  // },
  // {
  //   label: 'Desktop base',
  //   to: '/',
  // },
];
