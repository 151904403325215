import { Action, ActionType } from '@store/interfaces/aftership/orders';

const getOrders = (payload: any): Action => {
  return { type: ActionType.GET_ORDERS_REQUEST, payload };
};
const getOrdersSuccess = (payload: any): Action => {
  return { type: ActionType.GET_ORDERS_SUCCESS, payload };
};
const getOrdersFailure = (error?: any): Action => {
  return { type: ActionType.GET_ORDERS_FAILURE, error };
};

export const actions = {
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
};

const orders = {
  actions,
};

export default orders;
